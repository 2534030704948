import React from "react"

import Layout from "~/components/common/Layout"
import { getBackLink, getHeaderTitles, getNextLink, getNextTitle } from "~/services/pages"
import withProgress from "~/services/withProgress"
import { graphql, useStaticQuery } from "gatsby"
import HeroVideo from "~/components/page/HeroVideo"
import { Infobox, InfoboxDetailed } from "~/components/page/Infobox"
import { Heading, Text, Ul } from "~/components/common/Typography"
import { Box, Flex } from "reflexbox"
import Img from "gatsby-image"
import Copyright from "~/components/common/Copyright"
import Source from "~/components/common/Source"

const Page = ({ myPage = 3, mySubpage = 3 }) => {
  const data = useStaticQuery(graphql`
      query {
          hero: file(name: { eq: "4.4HERO" }) {
              childImageSharp {
                  fluid(maxWidth: 1440, quality: 90) {
                      ...GatsbyImageSharpFluid_withWebp_noBase64
                  }
              }
          }
          triggers: file(name: { eq: "4.4a-ADIDAS-triggers" }) {
              childImageSharp {
                  fluid(maxWidth: 900, quality: 90) {
                      ...GatsbyImageSharpFluid_withWebp_noBase64
                  }
              }
          }
      }
  `)

  const { hero, triggers } = data

  return (
    <Layout
      myPage={myPage}
      mySubpage={mySubpage}
      header={getHeaderTitles(myPage, mySubpage)}
      footer={{
        backLink: `${getBackLink(myPage, mySubpage)}`,
        nextText: `NEXT: ${getNextTitle(myPage, mySubpage)}`,
        nextLink: `${getNextLink(myPage, mySubpage)}`,
      }}
    >
      <HeroVideo
        headingLarge="What are some clues that would show you that one of your players might be experiencing this kind of stress response, that their smoke detector is going off?"
        image={hero}
        bg={"white"}
      />

      <Infobox>
        <Heading
          as={"h3"}
          fontSize={["24px", "32px", "48px"]}
          lineHeight={["33px", "42px", "60px"]}
          textAlign="center"
          fontFamily={"AdineuePROBoldWeb"}
        >
          These are some of the signs of stress responses:
        </Heading>
        <Box maxWidth={"tablet"} mx="auto" textAlign={"left"}>
          <Ul color={"black"}>
            <li>Shutting down</li>
            <li>Violent outbursts</li>
            <li>Emotional dysregulation (or impairment)</li>
            <li>Low sociability</li>
            <li>Lack of self-awareness</li>
            <li>Disengagement and unwillingness to participate</li>
          </Ul>
        </Box>
      </Infobox>

      <InfoboxDetailed
        headerText={
          "These are behaviors and conditions that come with triggers."
        }
        maxWidth={"100%"}
        contentMaxWidth={"mediumContent"}
      >
        <Flex flexDirection={["column"]} mt={4}>
          <Text>
            When you see these stress responses, you have to understand that
            although girls do have coping mechanisms, many of them get
            overwhelmed or compromised. To use coping skills, girls need access
            to the prefrontal cortex. This access is often shut down because of
            stress.
          </Text>
          <Box width={"100%"} mb={[6]} mt={5}>
            <Img
              fluid={triggers.childImageSharp.fluid}
              margin={"0 auto"}
              loading={"eager"}
            ></Img>
          </Box>
          <Box>
            <Text mt={0}>
              Remember, a trigger is anything that sets off the amygdala.
              Triggers are important for you as a coach to understand because
              they are the things that set off your players. The more you
              understand them, the more you will be able to prevent them. For
              example, you can avoid being a girl’s trigger and help girls
              navigate the triggers that are out of your control.
              <Source>32</Source>
            </Text>

            <Text>
              Girls will show up as they are. They won’t show up with a case
              file or a long list of what has happened to them. You will only be
              able to assess your girls through their behavior.
            </Text>
            <Text>
              As a coach, you cannot push them to talk before they are ready. It
              is your job to create a safe space, build close relationships, and
              pay attention to their behavior. Over time, you’ll learn more from
              the girls. Generally, it is crucial for coaches to become masters
              at observing behavior.
            </Text>
          </Box>
        </Flex>
      </InfoboxDetailed>

      <InfoboxDetailed
        headerText={"Why? Because behavior tells a story."}
        maxWidth={"100%"}
        contentMaxWidth={"desktop"}
        bg={"seafoam"}
        headerBackground={"seafoam"}
      >
        <Box bg={"white-panel"} mt="5" px={[5, 5, 6]} pb={[6, 6]} pt={4}>
          <Flex
            flexDirection={["column"]}
            mt={4}
            maxWidth={"mediumContent"}
            mx={"auto"}
          >
            <Box>
              <Text>
                As a coach, you can help each player separate her own identity
                from her behavior to show her that she can grow. You must
                remember: just because a girl demonstrates bad behavior doesn’t
                mean she is a bad kid.
              </Text>
              <Text>
                Even if the rewiring process has already begun, it isn’t always
                complete. Sometimes, girls can see positive choices and make
                positive decisions. Other times, they aren’t able to make the
                positive choice. Occasionally, they may not even be able to
                identify a positive choice in a situation.
              </Text>
              <Text>
                The good news is that even if the brain has been rewired, it can
                be rewired back, which is what we’ll cover next.
              </Text>
            </Box>
          </Flex>
        </Box>
      </InfoboxDetailed>

      <Copyright bg={"seafoam"}/>
    </Layout>
  )
}

export default withProgress(Page, 3, 3)
